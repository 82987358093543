<template>
	<div class="hua_xiaofang_home">
		<div class="home_content">
			<div class="avatar">
				<div class="image avatar_img" :style="{ backgroundImage: 'url(' + src1 + ')' }"></div>
			</div>
			<div class="details">
				<p class="job">
					嗨,新来的健康小零食品牌向你请安！
				</p>
			</div>

		</div>
		<div class="beian"><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备19011373号-7</a></div>
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			src1: require("../assets/logo.png"),
		};
	},
};
</script>

<style>
	.beian{position: fixed; bottom: 10px; width: 100%; line-height: 16px; text-align: center; font-size: 14px;}
</style>
